import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Slider,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from '@mui/material'
import { useFormik } from 'formik'
import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { pocketbase } from '../lib/config'
import { Answer } from '../types/global'
import { createQuiz } from '../util/useQuizzes'
import BasicDatePicker from './BasicDatePicker'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'

const fetchAnswers = async ({ queryKey }: any) => {
  const { startDate, endDate, correct } = queryKey[1] as {
    startDate: Date
    endDate: Date
    correct: boolean
  }
  return await pocketbase.collection('answers').getFullList<Answer>(undefined, {
    filter: `alternatives.correct=${correct}&&created>="${startDate.toISOString()}"&&updated<"${endDate.toISOString()}"`,
    expand: 'alternatives',
  })
}

type ReviewDialogProps = {
  open: boolean
  onClose: () => void
}

const ReviewDialog: React.FC<ReviewDialogProps> = (props) => {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      // one week ago
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
      endDate: new Date(),
      selectedMode: 'incorrect',
      correct: false,
      maxQuestions: 10,
    },
    onSubmit: async ({ maxQuestions }) => {
      const userId = pocketbase.authStore.model?.id
      if (
        questionIds === undefined ||
        questionIds.length === 0 ||
        maxQuestions < 1 ||
        userId === undefined
      ) {
        return
      }
      const quizSession = await createQuiz({
        questionIds,
        maxQuestions,
        userId,
        name: `Review ${dayjs(formik.values.startDate).format(
          'MMM D'
        )} - ${dayjs(formik.values.endDate).format('MMM D')}`,
      })
      navigate(`/quiz/${quizSession.id}`)
    },
  })

  const queryKey = [
    'answers',
    {
      startDate: formik.values.startDate,
      endDate: formik.values.endDate,
      correct: formik.values.correct,
    },
  ]
  const { data: answers, isFetching } = useQuery(queryKey, fetchAnswers, {
    enabled: props.open,
    staleTime: 1000 * 60,
  })

  const questionIds = useMemo(
    () =>
      answers
        ?.map((answer) => answer.question)
        .filter(
          // Remove duplicates
          (questionId, index, self) => self.indexOf(questionId) === index
        ),
    [answers]
  )

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="xs">
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Typography>
            Review questions answered during a time interval.
          </Typography>

          <Grid container spacing={2} sx={{ pt: 2, mb: 2 }}>
            <Grid item md={6}>
              <BasicDatePicker
                value={formik.values.startDate}
                label="From"
                onChange={(newValue) =>
                  formik.setFieldValue('startDate', newValue)
                }
              ></BasicDatePicker>
            </Grid>
            <Grid item md={6}>
              <BasicDatePicker
                value={formik.values.endDate}
                label="To"
                onChange={(newValue) =>
                  formik.setFieldValue('endDate', newValue)
                }
              ></BasicDatePicker>
            </Grid>
          </Grid>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Answer type</InputLabel>
            <Select
              size="small"
              label="Answer type"
              value={formik.values.selectedMode}
              onChange={(e) => {
                formik.setFieldValue('selectedMode', e.target.value)
                formik.setFieldValue(
                  'correct',
                  e.target.value === 'correct' ? true : false
                )
              }}
            >
              <MenuItem value="incorrect">Incorrect answer</MenuItem>
              <MenuItem value="correct">Correct answer</MenuItem>
            </Select>
          </FormControl>

          <Typography>Max questions</Typography>
          <Slider
            aria-label="Max questions"
            getAriaValueText={(value) => `${value} questions`}
            valueLabelDisplay={questionIds?.length ? 'on' : 'off'}
            min={0}
            max={questionIds?.length || 0}
            value={formik.values.maxQuestions}
            onChange={(e, value) => formik.setFieldValue('maxQuestions', value)}
            disabled={questionIds?.length === 0}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            disabled={
              questionIds?.length === 0 || formik.values.maxQuestions < 1
            }
            loading={isFetching}
          >
            Create quiz
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ReviewDialog
