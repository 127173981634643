import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  CircularProgress,
  Box,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { pocketbase } from '../lib/config'
import { QuizSession } from '../types/global'
import { useNavigate } from 'react-router-dom'

const fetchQuizSessions = async ({ queryKey }: any) => {
  const { page, perPage } = queryKey[1] as { page: number; perPage: number }
  return await pocketbase
    .collection('quiz_sessions')
    .getList<QuizSession>(page, perPage, {
      expand: 'quiz,answers(quiz_session)',
      sort: '-created',
    })
}

const QuizzesTable = () => {
  const [page, setPage] = useState(0) // 0 is first page in MUI
  const [perPage, setPerPage] = useState(10)

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const { data: quizSessions } = useQuery(
    ['quizzes', { page: page + 1, perPage }],
    fetchQuizSessions,
    {
      keepPreviousData: true,
    }
  )

  const navigate = useNavigate()

  const handleClick = (quizSession: QuizSession) => {
    navigate(
      quizSession.finished
        ? `/quiz/${quizSession.id}/result`
        : `/quiz/${quizSession.id}`
    )
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Questions</TableCell>
              <TableCell align="right">Progress</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quizSessions?.items.map((quizSession) => {
              const quiz = quizSession.expand?.quiz
              const questionIds = quiz?.questions
              const validAnswers = quizSession.expand?.[
                'answers(quiz_session)'
              ]?.filter(
                (answer) => answer.alternatives.length > 0 || answer.text != ''
              )
              const progress =
                ((validAnswers?.length ?? 0) / (questionIds?.length ?? 0)) * 100

              if (!quiz) {
                return null
              }

              return (
                <TableRow
                  key={quizSession.id}
                  hover
                  onClick={() => handleClick(quizSession)}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <TableCell component="th" scope="row">
                    {quiz.name || 'Quiz'}
                  </TableCell>
                  <TableCell>
                    {dayjs(quizSession.created).format('MMM D, YYYY HH:mm')}
                  </TableCell>
                  <TableCell>{quiz.questions.length}</TableCell>
                  <TableCell align="right">
                    {quizSession.finished ? (
                      <CheckCircleIcon color="success"></CheckCircleIcon>
                    ) : (
                      <Box sx={{ position: 'relative' }}>
                        <CircularProgress
                          variant="determinate"
                          size={24}
                          thickness={6}
                          value={100}
                          sx={(theme) => ({
                            position: 'absolute',
                            right: 0,
                            color: theme.palette.grey[300],
                          })}
                        />
                        <CircularProgress
                          variant="determinate"
                          size={24}
                          thickness={6}
                          value={progress}
                        />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={quizSessions?.totalPages || 0}
        rowsPerPage={perPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}

export default QuizzesTable
